import * as React from 'react'
import { graphql } from 'gatsby'
import { markdown as markdownStyles } from './styles/post.module.css'
import './styles/theme.css'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { PageContainer } from '../../components/blocks'
import Layout from '../../components/Layout'
import SEO from "../../components/SEO";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <PageContainer>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <div className="w-full lg:w-1/2 mt-40 mx-auto">
            <div className="">
              <GatsbyImage
                image={
                  getImage(post.frontmatter.coverImage) as IGatsbyImageData
                }
                alt=""
                className="object-cover w-full h-48 lg:h-96 rounded"
              />
            </div>
            <header className="mt-5 mb-10 block">
              <h1 itemProp="headline" className="text-3xl font-bold">
                {post.frontmatter.title}
              </h1>
              <p>{post.frontmatter.date}</p>
              <a
                href="#"
                className="mt-2 flex items-center opacity-80 hover:underline"
              >
                Read on Hashnode
              </a>
            </header>

            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
              className={markdownStyles}
            />
          </div>
        </article>
      </PageContainer>
    </Layout>
  )
}

export default BlogPostTemplate

export const Head = ({ data }) => {
  const post =  data.markdownRemark
  return (
      <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          pathname={`/blog${data.markdownRemark.fields.slug}`} />
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
