import React, { FC, PropsWithChildren } from 'react'
import Navbar from './Navbar'

const Layout: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Navbar />
    <main className="mb-40">{children}</main>
  </>
)

export default Layout
